var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "video-background",
        {
          staticClass: "theme--dark",
          staticStyle: { "max-height": "500px", height: "100vh" },
          attrs: {
            src: "" + require("../assets/kootto.webm"),
            poster: "" + require("../assets/home_1280.webp")
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    "border-radius": "40px",
                    "background-color": "#00000080"
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" })
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: " white--text",
                        staticStyle: {
                          visibility: "visible",
                          margin: "12px",
                          "text-align": "center"
                        }
                      },
                      [
                        _c(
                          "h1",
                          {
                            staticStyle: {
                              "font-size": "2em",
                              "font-weight": "bold"
                            }
                          },
                          [
                            _vm._v(
                              " Invite your guests and our private chef takes care of the rest. "
                            )
                          ]
                        ),
                        _c("div", { staticClass: "text-h6" }, [
                          _vm._v(
                            " Kootto is a new way to dine, bringing top restaurant quality in the comfort of your home. Our service also takes the stress out of organizing your event in order to never miss a moment with the people that count. "
                          )
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "v-row",
                    { attrs: { "align-content": "center", justify: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { href: "/search", target: "" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.$router.push({ path: "/search" })
                            }
                          }
                        },
                        [_vm._v("See Menus")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" })
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "v-container",
        {
          staticClass: "container margin-top-bottom-big",
          attrs: { fuild: "" }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "margin-left-right-default",
              attrs: { "align-content": "center", justify: "space-around" }
            },
            [
              _c("div", { attrs: { dark: "" } }, [
                _c("h2", { staticClass: "secondary-color-text" }, [
                  _vm._v(
                    "Bonus up to $200 for the first caterers to register *"
                  )
                ]),
                _c("br")
              ])
            ]
          ),
          _c(
            "v-row",
            {
              staticClass: "text-h5",
              attrs: { "align-content": "center", justify: "space-around" }
            },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-4 primary--text",
                  staticStyle: { "font-weight": "bold" }
                },
                [_vm._v("How it works")]
              )
            ]
          ),
          _c(
            "v-row",
            { attrs: { "align-content": "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "col-sm-3 col-12 text-center",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("../assets/icons/groceries.webp"),
                      height: "100",
                      contain: ""
                    }
                  }),
                  _c("div", [_vm._v("Our Chefs do the groceries")])
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "col-sm-3 col-12 text-center",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("../assets/icons/cook.webp"),
                      height: "100",
                      contain: ""
                    }
                  }),
                  _c("div", [_vm._v("They cook in your kitchen")])
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "col-sm-3 col-12 text-center",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("../assets/icons/serve.webp"),
                      height: "100",
                      contain: ""
                    }
                  }),
                  _c("div", [_vm._v("They serve you")])
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "col-sm-3 col-12 text-center",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c("v-img", {
                    attrs: {
                      src: require("../assets/icons/clean.webp"),
                      height: "100",
                      contain: ""
                    }
                  }),
                  _c("div", [_vm._v("They cleanup before leaving")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "container margin-top-bottom-big ",
          attrs: { fuild: "" }
        },
        [
          _c(
            "v-row",
            { attrs: { "align-content": "center", justify: "center" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "col-12 col-sm-6 text-center",
                  attrs: { "align-content": "center", justify: "center" }
                },
                [
                  _c(
                    "v-img",
                    {
                      staticClass: "brighten-v-img joinus round-corner",
                      attrs: {
                        src: require("../assets/serving_900.webp"),
                        srcset:
                          require("../assets/serving_900.webp") +
                          ", " +
                          require("../assets/serving_400.webp") +
                          " 500w",
                        height: "100%"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column align-stretch",
                          staticStyle: { height: "100%" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "container text-h6",
                              staticStyle: { color: "white" }
                            },
                            [
                              _c("div", { staticClass: "margin-default" }, [
                                _vm._v(
                                  " Kootto is always looking for chefs to provide more choices for our customers."
                                )
                              ]),
                              _c("div", { staticClass: "margin-default" }, [
                                _vm._v(
                                  "If you are a passionate chef and love to be in contact with your clients, please join us"
                                )
                              ])
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "div",
                            {
                              staticClass:
                                "align-end container margin-top-bottom"
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.click()
                                      _vm.$root.showSignup = true
                                      _vm.$root.showSignupChef = true
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("Become a Chef")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "container margin-top-bottom-big ",
          attrs: { fuild: "" }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "text-h5",
              attrs: { "align-content": "center", justify: "space-around" }
            },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-4 primary--text",
                  staticStyle: { "font-weight": "bold" }
                },
                [_vm._v("What we offer")]
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "gallery text-shadow",
              staticStyle: { padding: "0", "max-width": "unset" }
            },
            [
              _c(
                "v-img",
                {
                  staticClass:
                    "brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner",
                  attrs: {
                    src: require("../assets/course_250.webp"),
                    height: "250px",
                    "max-width": "250px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-stretch",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "container text-h4",
                          staticStyle: { color: "white" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-default dark-round-background"
                            },
                            [_vm._v("Course Meal")]
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-img",
                {
                  staticClass:
                    "brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner",
                  attrs: {
                    src: require("../assets/sharing_250.webp"),
                    height: "250px",
                    "max-width": "250px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-stretch",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "container text-h4",
                          staticStyle: { color: "white" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-default dark-round-background"
                            },
                            [_vm._v("Sharing Platters")]
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-img",
                {
                  staticClass:
                    "brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner",
                  attrs: {
                    src: require("../assets/cocktail_250.webp"),
                    height: "250px",
                    "max-width": "250px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-stretch",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "container text-h4",
                          staticStyle: { color: "white" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-default dark-round-background"
                            },
                            [_vm._v(" Cocktail food / Canapé ")]
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-img",
                {
                  staticClass:
                    "brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner",
                  attrs: {
                    src: require("../assets/grazing_250.webp"),
                    height: "250px",
                    "max-width": "250px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-stretch",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "container text-h4",
                          staticStyle: { color: "white" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "margin-default",
                              staticStyle: {
                                "border-radius": "40px",
                                "background-color": "#00000054"
                              }
                            },
                            [_vm._v("Grazing Table / Buffet")]
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-img",
                {
                  staticClass:
                    "brighten-v-img hover col-12 col-sm-3 text-center margin-top-bottom round-corner",
                  attrs: {
                    src: require("../assets/foodtruck_250.webp"),
                    height: "250px",
                    "max-width": "250px"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex flex-column align-stretch",
                      staticStyle: { height: "100%" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "container text-h4",
                          staticStyle: { color: "white" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "margin-default",
                              staticStyle: {
                                "border-radius": "40px",
                                "background-color": "#00000054"
                              }
                            },
                            [_vm._v("Food Truck/Street food")]
                          )
                        ]
                      ),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "caption", attrs: { "align-content": "start" } },
            [
              _c("div", [
                _vm._v("(*) Please see the details on our "),
                _c(
                  "a",
                  {
                    staticClass: "text-decoration-none",
                    attrs: { href: "/terms" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$router.push({ path: "/terms" })
                      }
                    }
                  },
                  [_vm._v("Terms and Conditions")]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }